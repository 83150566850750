
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wf-container-border
{
  margin: .5% 5% .5% 5%;
  border-width: 1px;
  border-style: outset;
  border-color: lightgray;
}
.wf-container {
  margin: auto;
	padding: 7px;
  display: flex;
  flex-wrap: wrap;
  width: 98%;
}
.padding-7px{
  padding: 7px;
}
.padding-2px{
  padding: 2px;
}
.wf-floatLeft {
  width: 260px;
  margin-left: 5px;
  float: left;
  text-align: left;
}

.wf-remainCenter {
  text-align: center;
  margin: auto;
  width: 500px;
}

.wf-floatRight {
  width: 260px;
  margin-right: 5px;
  float: right;
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInputBase-root.Mui-disabled {
  opacity: 0.5;
  color: red;
}

.contentstickerdata {
  align-content: left;
  float: left;
  font-size: medium;
}