.parent-card-ec {
  border: 2px solid #ebebeb;
}
/* stickers doc formatting */

.checkbox-card-container-sd {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 2px;
}

.checkbox-container-sd {
  display: flex;
  align-items: center;
  margin-right: 3px;
}

.card-sd-pets {
  background-color: #162b42 !important;
  color: white !important;
  padding: 16px;
  width: 100% !important;
}

.card-sd-vehicles {
  background-color: #162b42 !important;
  color: white !important;
  padding: 16px;
  width: 100% !important;
}
.stickernameicon-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.stickerdetails-typography {
  color: white;
  font-size: 14;
  font-family: "poppins" !important;
  font-weight: 400;
  margin-right: 1px;
}
.sticker-data-container {
  display: flex;
  justify-content: space-between;
}

.sticker-data-column {
  display: flex;
  flex-direction: column;
}

/* payment card */

.payment_card_box {
  width: 350px;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  color: rgba(221, 221, 221, 0.849);
  background-color: rgba(245, 244, 244, 0.075) !important;
}
.payment_card_box label {
  font-size: 14px;
}
.payment_input_card {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.payment_input_div {
  width: 100% !important;
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
}
.payment_inner_div {
  margin-right: 5px;
}

/* table components */
.table_card_box_tcr div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table_card_box_tcr div:nth-child(1) {
  border-bottom: 1px solid rgba(185, 185, 185, 0.493);
}
.table_card_box_tcr div P {
  width: 300px;
  text-align: center;
  font-size: 14px !important;
  padding: 10px;
}
.table_header_tcr {
  background-color: #162b42 !important;
  color: white !important;
}
.table_card_box_tcr div P .check_box {
  width: 10px;
}
.vard_box_items_tcr p {
  color: rgb(3, 3, 190);
}
@media screen and (max-width: 740px) {
  .table_card_box_tcr div {
    flex-direction: column;
    align-items: flex-start;
  }
  .table_card_box_tcr div P {
    width: 100% !important;
    text-align: left;
    padding-bottom: 6px;
  }
}

/* Document filename .card- */

.document_cards {
  background-color: white !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
  padding: 10px;
  border: 2px solid #ebebeb;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(136, 136, 136, 0.199) 0px 1px 2px !important;
}
.document_card {
  width: 250px;
  padding: 10px;

  background-color: #f4f4f4 !important;
  display: flex;

  margin: 5px;
  border-radius: 20px !important;
}

/* styling for card component new */

.rowcard-cc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.first_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: 2px 0px;
}
.second_row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 10px;
}
.first_row div {
  height: 30px;
  margin: 5px;
}
.second_row div {
  height: 30px;
  margin: 5px;
}
.address_wrapper {
  width: 100%;
}

.rowcardbutton-cc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}
.rowcardbutton-cc button {
  margin: 5px;
  height: 30px;
  width: 200px;
  margin: 5px;
}
.create_icons {
  color: #2f67a3 !important;
}
.buttonholder {
  display: flex;
  justify-content: flex-end;
}
.createappbutton {
  margin-top: 10px !important;
  border-radius: 30px;
  height: 30px;
}
@media screen and (max-width: 760px) {
  .rowcard-cc {
    flex-direction: column;
    align-items: start;
  }
  .first_row {
    grid-template-columns: 1fr;
  }
  .second_row {
    grid-template-columns: 1fr;
  }
  .input_icon {
    width: 20px !important;
  }
  .input_wrapper {
    width: 180%;
  }

  .input_wrapper input:focus {
    border-color: #0070f3;
  }
  .first_row div,
  .second_row div {
    margin: 0px;
  }
  .second_row div div p {
    font-size: 13px;
    font-weight: bold;
  }
  .first_row div div p {
    font-size: 13px;
    font-weight: bold;
  }

  .rowcardbutton-cc {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  .rowcardbutton-cc button {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 10px;
    width: 150px;
  }
}

@media screen and (max-width: 660px) {
  .input_wrapper {
    width: 150%;
  }
}
@media screen and (max-width: 560px) {
  .input_wrapper {
    width: 140%;
  }
}
@media screen and (max-width: 500px) {
  .input_wrapper {
    width: 120%;
  }
}

/* styling for search */
.main-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.rowcard-cc-ss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 85% !important;
  border-radius: "5px";
  box-shadow: 8px 8px 8px 8px rgba(43, 42, 42, 0.1) !important;
}
.first-row-ss {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: 2px 2px;
}
.second-row-ss {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.third-row-ss {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  gap: 2px 2px;
}
.first-row-ss div {
  height: 30px;
  margin: 5px;
}
.second-row-ss div {
  height: 30px;
  margin: 5px;
}
.third-row-ss-div {
  height: 30px;
  margin: 5px;
}
.address-wrapper-ss {
  width: 100%;
}

.side-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-date div {
  margin: 5px;
  height: 30px;
}

@media screen and (max-width: 850px) {
  .rowcard-cc-ss {
    flex-direction: column;
    align-items: start;
    justify-content: center !important;
  }
  .first-row-ss {
    grid-template-columns: 1fr;
  }
  .second-row-ss {
    grid-template-columns: 1fr;
  }

  .input-wrapper-ss {
    width: 250%;
  }
  .first-row-ss div,
  .second-row-ss div {
    margin: 0px;
  }

  .second-row-ss div div p {
    font-size: 13px;
    font-weight: bold;
  }
  .first-row-ss div div p {
    font-size: 13px;
    font-weight: bold;
  }

  .side-date {
    width: 100% !important;
    flex-direction: row !important;
    /* justify-content: center !important;
        align-items: center !important; */
    margin-top: 0px !important;
  }
  /* .side-date div {
    margin-left: 10px;
    font-size: 10px;
    width: 150px;
  } */
}

@media screen and (max-width: 660px) {
  .input-wrapper-ss {
    width: 150%;
  }
}
@media screen and (max-width: 560px) {
  .input-wrapper-ss {
    width: 140%;
  }
}
@media screen and (max-width: 500px) {
  .input-wrapper-ss {
    width: 120%;
  }
}
@media screen and (max-width: 48px) {
  .input-wrapper-ss {
    width: 120%;
  }
  .fee-label {
    width: 120%;
  }
}

/* /styling new design  */

.centered-filter {
  display: flex;
  justify-content: center;
}
.first_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 20px;
}
.first_div {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.first_div:not(:last-child) {
  border-right: 1px solid #333333;
}
.application_id_div {
  width: 80px;
}
.name_div {
  width: 200px;
}
.address_div {
  width: 300px;
}
.total_fee_div {
  width: 70px;
}

.id_text {
  color: grey !important;
}
.name_text {
  color: black !important;
  font-weight: bold !important;
  padding-left: 5px !important;
}

.address_text {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card_item_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}
@media screen and (max-width: 1025px) {
  .address_div {
    width: 200px !important;
  }
}
@media screen and (max-width: 910px) {
  .first_div p {
    font-size: 13px !important;
  }
  .address_div {
    width: 150px !important;
  }
  .name_div {
    width: 130px;
  }
  .lable_text {
    display: none !important;
  }
}
@media screen and (max-width: 780px) {
  .first_div {
    width: 100% !important;
    margin-left: -20px;
    border: none !important;
  }
  .button_wrapper {
    margin-top: 10px;
  }
  .button-47 {
    margin-top: 5px;
  }
  .lable_text {
    display: block !important;
  }
}
.fulfilled-label {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  margin-right: 8px;
  border: 2px solid green;
  background-color: #06b58c;
  color: white;
  text-transform: capitalize;
}
.pending-label {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  margin-right: 8px;
  border: 2px solid lightgray;
  background-color: lightgrey;
  color: black;
  text-transform: capitalize;
}
.rejected-label {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  margin-right: 8px;
  border: 2px solid red;
  background-color: red;
  color: white;
  text-transform: capitalize;
}
.approved-label {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  margin-right: 8px;
  border: 2px solid yellow;
  background-color: yellow;
  color: black;
  text-transform: capitalize;
}
.progress-label {
  display: inline-block;
  width: 100px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  margin-right: 8px;
  border: 2px solid blue;
  background-color: blue;
  color: white;
  text-transform: capitalize;
}
.view-app-button {
  font-size: 16px;
  margin-left: 4px;
  display: "inline";
  text-align: "";
  background-color: #162b42 !important;
  border-radius: 20px !important;
  color: white !important;
  width: 200px;
}
.renew-button {
  font-size: 16px;
  margin-left: 4px;
  display: "inline";
  text-align: "";
  background-color: #245080 !important;
  border-radius: 20px !important;
  color: white !important;
  width: 200px;
}

.addcar-vehicle {
  filter: brightness(0) invert(1);
  width: 35px;
  height: 28px;
}

/* styling error drawer */

.panel {
  z-index: 2;
  width: 100%;
  position: absolute;
  height: 100vh;
  background-color: #ffff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease-in-out;
}

.header {
  height: 60px;
  width: 100%;
  background: rgb(228, 55, 55);
}

.title {
  display: block;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}
.close-icon {
  z-index: 2;
  font-size: 21px;
  color: #ffff;
  transition: all 0.3s ease;
  cursor: pointer;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
}
.close-icon:hover {
  color: #a2333c;
}

.notifications-clearfix {
  overflow: hidden;
}
.line {
  position: absolute;
  height: auto;
  top: 60px;
  left: 27px;
  bottom: 0;
  width: 3px;
  background: #dbdbdb;
}
.notification {
  position: relative;
  z-index: 2;
  margin: 25px 20px 25px 43px;
  animation: here-am-i 0.5s ease-out 0.6s;
  animation-fill-mode: both;
}
.notification:hover {
  color: #bc4751;
  cursor: pointer;
}

.notification .circle {
  box-sizing: border-box;
  position: absolute;
  height: 11px;
  width: 11px;
  background: #d1606b;
  box-shadow: 0 0 0 3px #fff;
  border-radius: 6px;
  top: 0;
  left: -20px;
}

.any-data {
  display: block;
  font-size: 13px;
  line-height: 11px;
  margin-bottom: 2px;
}

.notification p {
  font-size: 18px;
  line-height: 15px;
  margin: 0;
}

.notification p b {
  font-weight: 600;
}

@keyframes here-am-i {
  from {
    transform: translate3d(0, 50px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.generate-report-button {
  /* margin-top:5px !important; */
  font-size: 14px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  width: 100px !important;
  background-color: #162b42 !important;
  color: white !important;
}

/* styling of clear filter button */
.clear-filter-button {
  margin-top: 8px !important;
  margin-left: 5px !important;
  font-size: 14px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  width: 200px !important;
  background-color: #162b42 !important;
  color: white !important;
}
.clear-filter-button:hover {
  background-color: #106ce7 !important;
}
.search-button-newapp {
  width: 50% !important;
  margin: 1% 25% !important;
  background-color: #162b42 !important;
  color: white !important;
}
.search-button-newapp:hover {
  background-color: #106ce7 !important;
}
.navbar-buttons-fp {
  text-transform: uppercase !important;
  background-color: #1a4ea3 !important;
  color: white !important;
  font-weight: bold !important;
}
.navbar-buttons-fp:hover {
  background-color: #106ce7 !important;
  color: white !important;
}

@media (max-width: 1000px) {
  .clear-filter-button {
    /* position: absolute; */
    /* top: 8px; */
    /* left: 210px; */
    width: 110px !important;
    margin-left: 60px;
  }
}
