@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  width: 70%;
}

@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}
.card-color{
  background-color: #06B58C
}
.in-progress {
  /* display: "inline";
  font-size: 13;
  text-align: "left";
  width: "80%";
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis"; */
  color: black;
}

div[role="progressbar"] {
  --size: 3rem;
  --fg: #369;
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: 2px;
  background: radial-gradient(
      closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
}

/* demo */

.rccs__card--unknown > div {
  background-image: url(./images/2.jpg);
}

.MuiInputBase-root.Mui-disabled {
  /* Your custom styles for disabled input here */
  opacity: 0.5;
  color: red;
}
